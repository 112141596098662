import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 40px;
`

export const Alias = styled.span`
  font-family: Karla;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #494949;
  margin-right: 8px;
`

export const Description = styled.p`
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #494949;
`

export const SectionHeader = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #161616;
  margin-bottom: 24px;
  margin-top: 72px;
`

export const DosageLegalText = styled.p`
  font-family: Karla;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #494949;
  margin-bottom: 40px;
`

export const RoaTypeContainer = styled.div`
  margin-bottom: 24px;
`

export const RoaType = styled.h3`
  font-family: Karla;
  font-style: normal;
  font-size: 18px;
  line-height: 150%;
  color: #494949;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 16px;
`

export const DosageAmountInfo = styled.p`
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #494949;
  text-transform: capitalize;
  margin-bottom: 4px;
`

export const Effect = styled.li`
font-family: Karla;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 150%;
color: #494949;
`

export const ToleranceLevel = styled.h3`
font-family: Karla;
font-style: normal;
font-size: 18px;
line-height: 150%;
color: #494949;
font-weight: bold;
text-transform: capitalize;
margin-bottom: 16px;
`

export const ToleranceInfoText = styled.p`
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #494949;
  text-transform: capitalize;
`