import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Header from "../components/shared/header";
import {
  Container,
  Alias,
  Description,
  SectionHeader,
  RoaTypeContainer,
  RoaType,
  DosageLegalText,
  DosageAmountInfo,
  Effect,
  ToleranceLevel,
  ToleranceInfoText
} from "../components/substance";
import SourceHandle from "../components/substance/source-handle";
import CtaLink from "../components/substance/cta-link";
import Helmet from "react-helmet";

const getFilteredRoas = substance => {
  const filteredRoas = {};
  if (substance.roas && Object.keys(substance.roas).length) {
    Object.keys(substance.roas).forEach(key => {
      if (substance.roas[key].name && substance.roas[key].units) {
        filteredRoas[substance.roas[key].name] = substance.roas[key];
      }
    });
  }
  return filteredRoas;
};

const renderRoas = filteredRoas => {
  const shouldDisplayRoas = Object.keys(filteredRoas).length;
  if (!shouldDisplayRoas) return null;
  return (
    <Container>
      <SectionHeader>Dosage</SectionHeader>
      <DosageLegalText>
        This dosage information is gathered from users and resources for educational purposes only. It is not a recommendation and should be
        verified with other sources for accuracy.
      </DosageLegalText>
      {Object.keys(filteredRoas).map(roaType => renderRoaType(filteredRoas[roaType]))}
      <SourceHandle handle="PsychonautWiki" />
      <CtaLink text="View dosage charts in the KnowDrugs app" />
    </Container>
  );
};

const renderRoaType = roa => (
  <RoaTypeContainer>
    <RoaType>{roa.name}</RoaType>
    {Object.keys(roa.dose).map(doseKey => {
      const dosage = roa.dose[doseKey];
      const { min, max } = dosage;
      return <DosageAmountInfo>{`${doseKey}: ${min}-${max}${roa.units}`}</DosageAmountInfo>;
    })}
  </RoaTypeContainer>
);

const renderDuration = () => (
  <Container>
    <SectionHeader>Duration</SectionHeader>
    <CtaLink text="View duration charts in the KnowDrugs app" />
  </Container>
);

const renderTolerance = substance => {
  if (!substance.tolerance || !substance.tolerance.content) return null;
  return (
    <Container>
      <SectionHeader>Tolerance</SectionHeader>
      {Object.keys(substance.tolerance.content).map(toleranceLevel => {
        if (substance.tolerance.content[toleranceLevel]) {
          return (
            <>
              <ToleranceLevel>{toleranceLevel}</ToleranceLevel>
              <ToleranceInfoText>{substance.tolerance.content[toleranceLevel]}</ToleranceInfoText>
            </>
          );
        }
        return null;
      })}
      <SourceHandle handle={substance.tolerance.sourceName ? substance.tolerance.sourceName : "PsychonautWiki"} />
    </Container>
  );
};

const renderHarmPotential = substance => {
  if (!substance.harmPotential || !substance.harmPotential.content) return null;
  return (
    <Container>
      <SectionHeader>Harm potential</SectionHeader>
      <ToleranceInfoText>{substance.harmPotential.content}</ToleranceInfoText>
      <SourceHandle handle={substance.harmPotential.sourceName ? substance.harmPotential.sourceName : "PsychonautWiki"} />
    </Container>
  );
};

const renderMarquis = substance => {
  if (!substance.marquis || !substance.marquis.content) return null;
  return (
    <Container>
      <SectionHeader>Marquis</SectionHeader>
      <ToleranceInfoText>{substance.marquis.content}</ToleranceInfoText>
      <SourceHandle handle={substance.marquis.sourceName ? substance.marquis.sourceName : "Tripsit.me"} />
    </Container>
  );
};

const renderProofTime = substance => {
  if (!substance.proofTime || !substance.proofTime.content) return null;
  return (
    <Container>
      <SectionHeader>Proof time</SectionHeader>
      {Object.keys(substance.proofTime.content).map(sampleType => {
        if (substance.proofTime.content[sampleType]) {
          return (
            <>
              <ToleranceLevel>{sampleType}</ToleranceLevel>
              <ToleranceInfoText>{substance.proofTime.content[sampleType]}</ToleranceInfoText>
            </>
          );
        }
        return null;
      })}
      <SourceHandle handle={substance.proofTime.sourceName ? substance.proofTime.sourceName : "PsychonautWiki"} />
    </Container>
  );
};

const renderEffects = substance => {
  if (!substance.effects || !substance.effects.content) return null;
  return (
    <Container>
      <SectionHeader>Effects</SectionHeader>
      <ul>{substance.effects.content.map((effect, index) => !!effect && <Effect key={`effect-${index}`}>{effect}</Effect>)}</ul>
      <SourceHandle handle={substance.effects.sourceName ? substance.effects.sourceName : "Tripsit.me"} />
    </Container>
  );
};

const renderInteractions = () => (
  <Container>
    <SectionHeader>Interactions</SectionHeader>
    <CtaLink text="View all interactions in the KnowDrugs app" />
  </Container>
);

const renderAliases = substance => {
  if (!substance.aliases) return null;
  return (
    <Container>
      {substance.aliases.map(alias => (
        <Alias key={alias}>{alias}</Alias>
      ))}
    </Container>
  );
};

export default ({ data }) => {
  const substance = data.substance;
  const filteredRoas = getFilteredRoas(substance);
  return (
    <Layout>
      <Helmet>
        <title>KnowDrugs: {substance.name}</title>
        <meta property="og:description" content={substance.description} />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:description" content={substance.description} />
        <meta property="twitter:title" content={`KnowDrugs: ${substance.name}`} />
        <meta property="twitter:site" content="@know_drugs" />
        <meta property="twitter:image" content={substance.imageUrl} />
      </Helmet>
      <div key={substance.id}>
        <Header category="Substances" title={substance.name}>
          {renderAliases(substance)}
        </Header>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: -56,
            marginBottom: 80
          }}
        >
          <img src={substance.imageUrl} style={{ maxHeight: 300 }} alt={`${substance.name} molecular scheme`} />
        </div>

        <Container>
          <Description>{substance.description}</Description>
        </Container>

        {renderHarmPotential(substance)}

        {renderRoas(filteredRoas)}

        {renderDuration()}

        {renderEffects(substance)}

        {renderInteractions()}

        {renderTolerance(substance)}

        {renderMarquis(substance)}

        {renderProofTime(substance)}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    substance(fields: { slug: { eq: $slug } }) {
      id
      name
      description
      aliases
      imageUrl
      effects {
        content
        sourceName
      }
      marquis {
        content
      }
      proofTime {
        content {
          blood
          urine
        }
        sourceName
      }
      harmPotential {
        content
        sourceName
      }
      tolerance {
        content {
          full
          half
          zero
        }
        sourceName
      }
      roas {
        buccal {
          name
          units
          dose {
            light {
              min
              max
            }
            common {
              min
              max
            }
            strong {
              min
              max
            }
          }
        }
        oral {
          name
          units
          dose {
            light {
              min
              max
            }
            common {
              min
              max
            }
            strong {
              min
              max
            }
          }
        }
        inhaled {
          name
          units
          dose {
            light {
              min
              max
            }
            common {
              min
              max
            }
            strong {
              min
              max
            }
          }
        }
        insufflated {
          name
          units
          dose {
            light {
              min
              max
            }
            common {
              min
              max
            }
            strong {
              min
              max
            }
          }
        }
        intravenous {
          name
          units
          dose {
            light {
              min
              max
            }
            common {
              min
              max
            }
            strong {
              min
              max
            }
          }
        }
        rectal {
          name
          units
          dose {
            light {
              min
              max
            }
            common {
              min
              max
            }
            strong {
              min
              max
            }
          }
        }
        smoked {
          name
          units
          dose {
            light {
              min
              max
            }
            common {
              min
              max
            }
            strong {
              min
              max
            }
          }
        }
        sublingual {
          name
          units
          dose {
            light {
              min
              max
            }
            common {
              min
              max
            }
            strong {
              min
              max
            }
          }
        }
        transdermal {
          name
          units
          dose {
            light {
              min
              max
            }
            common {
              min
              max
            }
            strong {
              min
              max
            }
          }
        }
      }
    }
  }
`;
