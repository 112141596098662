import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%
`

const Handle = styled.p`
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #494949;
  text-align: right
`

export default ({handle}) => (
  <Container>
    <Handle>Source: {handle}</Handle>
  </Container>
)
